import { faDonate } from "@fortawesome/pro-duotone-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import scholarshipsDescriptionStyle from "assets/jss/material-kit-pro-react/views/scholarshipsPageSections/scholarshipsDescriptionStyle.jsx"
// core components
import Button from "components/CustomButtons/Button.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import React from "react"

const useStyles = makeStyles(scholarshipsDescriptionStyle)

export default function ScholarshipsDescription() {
  const classes = useStyles()
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer justifyContent="center">
          <GridItem md={10} sm={12} className={classes.textCenter}>
            <h2 className={classes.title}>Giving Back</h2>
            <p className={classes.mainDescription}>
              Our mission is to promote the Edgerton business community, social
              responsibility, and to raise funds to support the education of our
              youth. Each spring, proceeds from the previous year’s festival
              fund scholarships to graduating Edgerton High School seniors.
            </p>
            <h4 className={classes.title}>Interested in Helping?</h4>
            <p className={classes.description}>
              Chilimania needs help from everyone in order to continue giving
              back to community and students, please click the donations button
              below to visit our donations page where you can contribute to our
              cause. <b>EVERY LITTLE BIT COUNTS!</b>
            </p>
            <Button
              href="https://chilimania.givingfuel.com/chilimania-donations"
              round
              color="secondary"
            >
              <FontAwesomeIcon icon={faDonate} />
              Donate Now
            </Button>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  )
}
