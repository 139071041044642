import {
  container,
  mrAuto,
  mlAuto,
  description,
  section,
  title,
} from "assets/jss/material-kit-pro-react.jsx"

const scholarshipsDescriptionStyle = {
  container,
  description,
  section: {
    ...section,
    padding: "80px 0px 0px 0px",
  },
  title: {
    ...title,
    marginTop: "0",
  },
  textCenter: {
    textAlign: "center",
  },
  aboutDescription: {
    padding: "70px 0 0 0",
  },
  mrAuto,
  mlAuto,
  chiliPepperIcon: {
    ...mlAuto,
    ...mrAuto,
    width: "50%",
  },
  mainDescription: {
    ...description,
    fontSize: "1.15rem",
    fontWeight: "400",
    color: "#777",
  },
}

export default scholarshipsDescriptionStyle
